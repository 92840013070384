/**
 * Created by Ilya Savinov 03/18/24
 *
 * this is progress indicator component
 * @param checked - boolean - checked checkbox
 * @param label - string - label
 * @param disabled - boolean - disabled for changing
 * @param onChange - handle change checkbox state
 * @param showInput - boolean - show number input
 * @param inputValues - array of input values
 * @param setValues - handle values change
 * @param testId - test id for e2e tests
 */
import React from 'react';

import InputText from './InputText';

interface CheckboxInput {
  label: string;
  value?: string | number;
}

interface Props {
  checked?: boolean;
  label: string;
  disabled?: boolean;
  onChange?: () => void;
  showInput?: boolean;
  inputValues?: CheckboxInput[];
  setValues?: (newValues: CheckboxInput[]) => void;
  testId?: string;
  inputSuffix?: string;
  spacing?: number;
  onBlur?: () => void;
  inputType?: 'number' | 'text';
  minValue?: number;
}

const CheckboxWithInput: React.FC<Props> = ({
  checked,
  label,
  disabled,
  onChange,
  inputValues,
  setValues,
  showInput,
  spacing = 10,
  testId,
  inputSuffix = '',
  inputType = 'number',
  minValue,
  onBlur,
}) => (
  <div className="checkbox-with-input">
    <label className="checkbox-label">
      <input
        type="checkbox"
        className="checkbox-input"
        checked={checked}
        value={label}
        disabled={disabled}
        onChange={onChange}
        data-testid={testId}
      />
      {label}
    </label>
    {checked && showInput && (
      <div className="option-inputs-row" style={{ marginTop: spacing }}>
        {inputValues?.map(({ label: inputLabel, value }, index) => (
          <InputText
            key={inputLabel}
            title={inputLabel}
            value={value}
            type={inputType}
            suffix={inputSuffix}
            min={minValue}
            onBlur={onBlur}
            className="option-input"
            testId={`${testId}-input-${index}`}
            onChange={(p) => {
              const newInputs = inputValues?.map((input) => {
                if (input.label === inputLabel) {
                  return {
                    ...input,
                    value: (p as string) || 0,
                  };
                }

                return input;
              });

              setValues?.(newInputs);
            }}
          />
        ))}
      </div>
    )}
  </div>
);

export default CheckboxWithInput;
