/**
 * Created by Max Gornostayev 01/15/24
 *
 * path: /
 *
 * this is home page
 */

// eslint-disable-next-line
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import pageUrl from '../const/pages';
import useStore from '../hooks/useStore';
import loggerInstance from '../core/Logger';

const HomePage = observer(() => {
  // navigate variable that is used to go to another screen
  const navigate = useNavigate();

  // get user store
  const { userStore } = useStore();

  // is authorized
  const { isAuthorized, customer, isCustomerFilled, isContactExist } = userStore;

  if (!isAuthorized) {
    // clear stores or make other action to reset the application
  }

  // is mount function
  useEffect(() => {
    if (!isContactExist) {
      navigate(pageUrl.page404);
    } else {
      loggerInstance.debug(customer);
      navigate(isCustomerFilled ? pageUrl.basicData : pageUrl.missingRequiredData);
    }
  }, []);

  return null;
});

export default HomePage;
