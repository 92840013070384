/**
 * Created by Max Gornostayev 2/28/22
 *
 * MODEL - customer model
 */
import { UserType } from 'bsh-calculator';

import { ICustomer } from '../interfaces/IModels';

class Customer implements ICustomer {
  objectId?: string = '';

  firstName?: string = '';

  lastName?: string = '';

  email?: string = '';

  phone?: string = '';

  city?: string = '';

  country?: string = '';

  street?: string = '';

  streetNumber?: string = '';

  postcode?: string = '';

  crmContactId?: string = '';

  crmResponsibleUserId?: string = '';

  departmentId?: string = '';

  salutation: string = '';

  companyName?: string = '';

  companyCrmId?: number;

  type?: UserType = 'contact';

  get fullName(): string {
    let fullName: string = '';

    if (this.firstName) {
      fullName += `${this.firstName} `;
    }

    if (this.lastName) {
      fullName += this.lastName;
    }

    return fullName;
  }
}

export default Customer;
