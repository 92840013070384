/**
 * Created by Ilya Savinov 30/01/24
 *
 * this is a Tooltip component
 *
 * props:
 * @label - string - label of the button
 * @imageSrc - string - image link
 * @isActive - bool? - is button selected
 * @onClick - () => void - position of the tooltip depends on children
 * @className - string? - custom class name
 */
import React, { useMemo } from 'react';

interface ImageButtonProps {
  label?: string;
  imageSrc?: string;
  isActive?: boolean;
  onClick?: () => void;
  className?: string;
  isSmall?: boolean;
  fullImage?: boolean;
}

const ImageButton: React.FC<ImageButtonProps> = ({ label, imageSrc, isActive, onClick, isSmall, className, fullImage }) => {
  const wrapperClassName = useMemo(() => {
    let wrapperClass = 'image-button';

    if (isSmall) wrapperClass += ' small';
    if (isActive) wrapperClass += ' active';
    if (className) wrapperClass += ` ${className}`;
    if (!imageSrc) wrapperClass += ' center';

    return wrapperClass;
  }, [isActive, className]);

  return (
    <button className={wrapperClassName} onClick={onClick} data-testid={`${label}`}>
      {imageSrc && (
        <div className={`image-wrapper ${isSmall ? 'small' : ''} ${fullImage ? 'full-image' : ''}`}>
          <img src={imageSrc} alt={label} />
        </div>
      )}
      {label && <span className="small">{label}</span>}
    </button>
  );
};

export default ImageButton;
