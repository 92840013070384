/**
 * Created by Ilya Savinov on 01/03/24
 *
 * this is API requests to interact with parse tables
 */
import { omit } from 'lodash';

import { ICustomer } from '../../interfaces/IModels';
import { toastManager } from '../../lib/ToastManager';
import { APIResponse, ParseCustomer, SteleSenec, WallboxJuiceBooster, FinancingForm, QuotePdf } from '../../types/TGeneral';
import CustomAPI from '../CustomAPI';
import { ICacheCompany } from '../../interfaces/ICache';

export type SelfSufficienceForm = {
  selfSufficienceValue: number;
  selfSufficienceManually: boolean;
  yearlyElectricityTraffic: number;
  pvPerformance: number;
  usableStorage: number;
};

export type CalculationParse = WallboxJuiceBooster &
  SteleSenec &
  FinancingForm & {
    objectId: string;
    crmDealId: string;
    createdAt: string;
    updatedAt: string;
    electricityConsumption?: number;
    forecastPeriod?: number;
    grossElectricityCosts?: number;
    powerConsumption?: number;
    priceInflation?: number;
    secondElectricityMeter?: boolean;
    modulType: Nullable<string>;
    storageBrand: Nullable<string>;
    storages: Nullable<string>;
    internalCalculatedStorageValue: Nullable<string>;
    moduleAreaForms: Nullable<any[]>;
    newCounterBox: boolean;
    moveSat: boolean;
    withoutZaehlerhelden: boolean;
    privatCounterBox: boolean;
    privatCounterBoxNumber?: number;
    moveRoofSteps: boolean;
    fem111SmartCounter: boolean;
    fem111SmartCounterNumber: number;
    femAppPowerHeat: Nullable<string>;
    enerPlug: boolean;
    enerPlugNumber?: number;
    enerPlugSetNumber?: number;
    drohn: boolean;
    selfSufficienceValue?: number;
    selfSufficienceForm?: SelfSufficienceForm;
    centralHeatingExist?: boolean;
    selectionHotWaterHeatPump?: Nullable<string>;
    storageExtensionSelection?: Nullable<string>;
    wallboxSelection?: Nullable<string>;
    wallboxQuantity?: number;
    blackoutBoxSelection?: Nullable<string>;
    memoryExtensionSelection?: Nullable<string>;
    numberExistingBatteries?: number;
    numberAdditionalBattery?: number;
    internalLastPageUrl?: string;
    checkboxListMenu?: string;
  };

const Parse = {
  /**
   * post customer data to parse to create a row in Customer table
   * @param newCustomer - ICustomer - customer data from user store
   * @param dealId - string - dealId from user store
   * @param quoteNumber - string - quote number from user store
   */
  createCustomer: async (newCustomer: ICustomer, dealId: string, quoteNumber: string): Promise<Nullable<ParseCustomer>> => {
    try {
      const customer = {
        ...newCustomer,
        crmDealId: dealId,
        quoteNumber,
      };

      const res = (await CustomAPI.request('post', '/parse/classes/Customer', customer)) as APIResponse<ParseCustomer>;

      return Array.isArray(res.data) ? res.data[0] : res.data;
    } catch (error: any) {
      toastManager.showError(error.message);
    }

    return null;
  },

  /**
   * get customer data from parse to transfer this data to the DataStore
   * @param newCustomer - ICustomer - customer data from user store
   * @param dealId - string - dealId from user store
   * @param quoteNumber - string - quote number from user store
   */
  getCustomer: async (dealId: string, quoteNumber: string): Promise<Nullable<ParseCustomer>> => {
    try {
      const res = (await CustomAPI.request(
        'get',
        `/parse/classes/Customer?where={"crmDealId": "${dealId}", "quoteNumber": "${quoteNumber}"}`,
      )) as APIResponse<ParseCustomer>;

      return Array.isArray(res.data) ? res.data[0] : res.data;
    } catch (error: any) {
      toastManager.showError(error.message);
    }

    return null;
  },

  /**
   * update customer data in parse
   * @param newCustomer - ICustomer - customer data from user store
   * @param objectId - string - customer parse object id
   */
  updateCustomer: async (newCustomer: ICustomer, quoteNumber: string, objectId: string) => {
    try {
      await CustomAPI.request('put', `/parse/classes/Customer/${objectId}`, {
        ...omit(newCustomer, 'objectId'),
        quoteNumber,
      });
    } catch (error: any) {
      toastManager.showError(error.message);
    }
  },

  /**
   * update one property in Customer parse table
   * @param propertyName - string - property name
   * @param value - any - new value of the property
   * @param objectId - string - id of the object
   */
  updateCustomerProperty: async (propertyName: string, value: any, objectId: string) => {
    await CustomAPI.request('put', `/parse/classes/Customer/${objectId}`, { [propertyName]: value });
  },

  /**
   * post calculation object in Calculation parse table
   * @param form - ProfitabilityForm - basic data form
   * @param crmDealId - string - deal id from url params
   */
  createCalculationForm: async (data: { [key in string]: any }) => {
    try {
      const res = (await CustomAPI.request('post', '/parse/classes/Calculation', data)) as APIResponse<CalculationParse>;

      return res;
    } catch (error: any) {
      toastManager.showError(error.message);
    }

    return null;
  },

  /**
   * update one property in Calculation table
   * @param propertyName - string - property name
   * @param value - any - new value of the property
   * @param objectId - string - id of the object
   */
  updateCalculationProperty: async (propertyName: string, value: any, objectId: string) => {
    await CustomAPI.request('put', `/parse/classes/Calculation/${objectId}`, { [propertyName]: value });
  },

  /**
   * get calculation object from Calculation parse table
   * @param dealId - string - deal id from url params
   */
  getCalculationData: async (dealId: string): Promise<Nullable<CalculationParse>> => {
    try {
      const res = await CustomAPI.request<CalculationParse>('get', `/parse/classes/Calculation?where={"crmDealId":"${dealId}"}`);

      if (Array.isArray(res.data)) {
        return res.data[0];
      }

      return res.data;
    } catch (error: any) {
      toastManager.showError(error.message);
    }

    return null;
  },

  /**
   * synchronize caluclation object in parse
   * @param data - any data
   * @param objectId - string - id of calculation parse object
   */
  async syncCalculationObject(data: { [key in string]: Nullable<any> }, objectId: string) {
    const res = await CustomAPI.request('put', `/parse/classes/Calculation/${objectId}`, {
      ...data,
    });

    if (res.isSucceed === false) {
      throw new Error('Parse saving error');
    }
  },

  /**
   * fetch latest generated pdfs for current deal
   * @param crmDealId - string - crm deal id
   */
  async getPdfQuotes(crmDealId: string) {
    try {
      const res = await CustomAPI.request<QuotePdf>(
        'get',
        `/parse/classes/QuotePdf?where={"dealId":"${crmDealId}"}&order=-createdAt&limit=1`,
        undefined,
        undefined,
        60000,
      );

      return res.data;
    } catch (error: any) {
      toastManager.showError(error.message);
    }

    return null;
  },

  async getEmployee(crmId: string) {
    try {
      const res = await CustomAPI.request<any>(
        'get',
        `/parse/classes/Employee?where={"crmId":${Number(crmId)}}&limit=1`,
        undefined,
        undefined,
        60000,
      );

      return res.data[0] ?? null;
    } catch (error: any) {
      toastManager.showError(error.message);
    }

    return null;
  },

  async getCompanies() {
    try {
      const res = await CustomAPI.request<ICacheCompany>('get', '/parse/classes/Company?limit=10000', undefined, undefined, 60000);

      return res.data as ICacheCompany[];
    } catch (error: any) {
      toastManager.showError(error.message);
    }

    return null;
  },

  async removeRoofPlanPhoto(crmDealId: string) {
    const deals = await CustomAPI.request<any>('get', `/parse/classes/Deal?where={"crmDealId":"${crmDealId}"}`);
    const dealId = deals.data[0].objectId;

    await CustomAPI.request('put', `/parse/classes/Deal/${dealId}`, {
      imageRoofPlan: '',
    });
  },
  async updateImageRoofPlanPhoto(crmDealId: string, url: Nullable<string>) {
    const deals = await CustomAPI.request<any>('get', `/parse/classes/Deal?where={"crmDealId":"${crmDealId}"}`);

    if (deals.data.length) {
      const dealId = deals.data[0].objectId;

      await CustomAPI.request('put', `/parse/classes/Deal/${dealId}`, {
        imageRoofPlan: url,
      });
    }
  },
};

export default Parse;
