/**
 * Created by Max Gornostayev 02/15/22
 *
 * this is not found page
 */

import React from 'react';
import { observer } from 'mobx-react';

import i18n from '../i18n';
import errorImage from '../assets/images/errorPage.jpg';
import useStore from '../hooks/useStore';

const NotFound = observer(() => {
  const { userStore } = useStore();
  const { isAuthorized } = userStore;

  return (
    <div className="app missing-data-page notfound-page">
      <div className="missing-wrapper">
        <div className="missing-content">
          <div className="container">
            <div className="row">
              <div className="column-left-top">
                <h1 className="heading">{i18n.t('notFoundPage.title')}</h1>
                <div className="desc">{!isAuthorized ? i18n.t('notFoundPage.desc') : i18n.t('notFoundPage.emptyContact')}</div>
              </div>
              <div className="column-right-top">
                <img src={errorImage} alt="erroCalculator" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default NotFound;
