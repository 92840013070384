import React, { useState, useRef, useEffect } from 'react';

import InputText, { InputRefType } from './InputText';
import { IInputTextProps } from '../../interfaces/IComponents';

type Option = { value: number; label: string };

interface IAutocompleteInputTextProps extends IInputTextProps {
  options: Option[]; // List of autocomplete suggestions
  onOptionSelect: (option: Option) => void; // Callback when an option is selected
  title?: string; // Add title prop
}

const AutocompleteInputText = React.forwardRef<InputRefType, IAutocompleteInputTextProps>((props, ref) => {
  const {
    options,
    onOptionSelect,
    value,
    onChange,
    className,
    title, // Destructure title
    ...inputTextProps
  } = props;

  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const [activeOption, setActiveOption] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Filter options based on the current value
  useEffect(() => {
    if (value && value.toString().trim() !== '') {
      const filtered = options.filter((option) => option.label.toLowerCase().includes(value.toString().toLowerCase()));

      setFilteredOptions(filtered.slice(0, 10));
    } else {
      setShowOptions(false);
    }
  }, [value, options]);

  // Handle clicking outside the autocomplete
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option: Option) => {
    onOptionSelect(option);
    setShowOptions(false);
    setFilteredOptions([]);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown' && activeOption < filteredOptions.length - 1) {
      setActiveOption((prevActiveOption) => prevActiveOption + 1);
    } else if (e.key === 'ArrowUp' && activeOption > 0) {
      setActiveOption((prevActiveOption) => prevActiveOption - 1);
    } else if (e.key === 'Enter' && filteredOptions.length > 0) {
      handleOptionClick(filteredOptions[activeOption]);
    } else if (e.key === 'Escape') {
      setShowOptions(false);
    }
  };

  const handleChange = (val: string | number) => {
    onChange?.(val);
    setShowOptions(true);
  };

  return (
    <div className={`autocomplete-input-text ${className}`} ref={wrapperRef}>
      {title && <div className="autocomplete-title">{title}</div>}
      <InputText ref={ref} value={value} onChange={handleChange} onKeyDown={handleKeyDown} className="input-text" {...inputTextProps} />
      {showOptions && (
        <div className="select-dropdown">
          {filteredOptions.map((option, index) => (
            <div
              key={option.value}
              className={`select-item ${activeOption === index ? 'active' : ''}`}
              onClick={() => handleOptionClick(option)}
            >
              <span>{option.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

AutocompleteInputText.displayName = 'AutocompleteInputText';

export default AutocompleteInputText;
